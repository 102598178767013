import React from 'react';

const Credit = () => {
  return (
    <div className='credit'>
      <p>Copyright &copy; fightthelandlord.io</p>
      <p>Graphics and Icons by Jeremy Wang</p>
    </div>
  );
};

export default Credit;

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get('/api/users');
      setLeaderboard(res.data);
    };
    try {
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div>
      <h1 className='large text-center'>Top 10</h1>
      <ul className='leaders'>
        {leaderboard.map((user, index) => (
          <li key={index} className='leader'>
            <span>
              {index + 1}. {user.username}
            </span>{' '}
            <span>${user.earning.toLocaleString()}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Leaderboard;
